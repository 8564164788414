<template>
  <div>
    <a-spin :spinning="confirmLoading">
      <div class="descriptions-view" style="margin-bottom: 10px">
        <table style="width: 100%">
          <tbody>
            <tr class="descriptions-row">
              <th class="descriptions-item-label" colspan="2">方法名称</th>
              <th class="descriptions-item-label" colspan="2">方法依据</th>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item-content" colspan="2">{{ typeRecord.methodName }}</td>
              <td class="descriptions-item-content" colspan="2">{{ typeRecord.standardName }} {{ typeRecord.standardCode }}</td>
            </tr>
            <tr class="descriptions-row">
              <th class="descriptions-item-label">标液类型</th>
              <th class="descriptions-item-label">浓度</th>
              <th class="descriptions-item-label" colspan="2">设备类型</th>
            </tr>
            <tr class="descriptions-row">
              <td class="descriptions-item-content">
                <a-tag color="#98a4f9" style="line-height: 28px; height: 28px">{{ typeRecord.solutionName }}</a-tag>
              </td>
              <td class="descriptions-item-content">{{ typeRecord.solutionPurity }}</td>
              <td class="descriptions-item-content" colspan="2">{{ typeRecord.devName }}</td>
            </tr>
            <tr class="descriptions-row" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
              <th class="descriptions-item-label">参比溶液</th>
              <th class="descriptions-item-label">比色皿</th>
              <th class="descriptions-item-label">波长</th>
              <th class="descriptions-item-label">回归方程</th>
            </tr>
            <tr class="descriptions-row" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
              <td class="descriptions-item-content">
                <template v-if="typeRecord.referenceSolutionName">
                  <a-tag color="burlywood" style="line-height: 28px; height: 28px">{{ typeRecord.referenceSolutionName }}</a-tag>
                </template>
              </td>
              <td class="descriptions-item-content">{{ typeRecord.thickness }}</td>
              <td class="descriptions-item-content">{{ typeRecord.wavelength }}</td>
              <td class="descriptions-item-content">{{ typeRecord.regressionEquation }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <a-card v-bind="baseFormCard" title="曲线点位信息" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
        <template>
          <!--          :data="hotData"-->
          <hot-table
            ref="hotTableComponent"
            :settings="hotSetting"
            :colHeaders="curveDrawTable.columns.map((item) => item.title)"
            :columns="
              curveDrawTable.columns.map((item) => {
                return { data: item.dataIndex, readOnly: item.readOnly }
              })
            "
            style="width: 100%"
          >
          </hot-table>
        </template>
        <!--        <a-table v-bind="curveDrawTable">-->
        <!--          <template v-for="(col, i) in curveDrawTable.columns" :slot="col.dataIndex" slot-scope="text, itemRecord">-->
        <!--            <a-input-->
        <!--              :key="col.dataIndex + itemRecord.drawIndex"-->
        <!--              v-model="itemRecord[col.dataIndex]"-->
        <!--              v-if="itemRecord.editable && col.dataIndex !== 'drawIndex' && col.dataIndex === 'remark'"/>-->
        <!--            <a-input-number-->
        <!--              :key="col.dataIndex + itemRecord.drawIndex"-->
        <!--              v-model="itemRecord[col.dataIndex]"-->
        <!--              v-else-if="itemRecord.editable && col.dataIndex !== 'drawIndex' && col.dataIndex !== 'remark'"-->
        <!--              style="width: 90%"-->
        <!--              :min="0"/>-->
        <!--            <template v-else>{{ text }}</template>-->
        <!--          </template>-->
        <!--          <template #action="data,record,index">-->
        <!--            <a v-if="record.editable" @click="record.editable = false">确定</a>-->
        <!--            <a v-else @click="record.editable = true">编辑</a>-->
        <!--            <a-divider type="vertical"/>-->
        <!--            <a @click="deleteCurveSite(index)">作废</a>-->
        <!--          </template>-->
        <!--        </a-table>-->
        <!--        <a-button style="width: 100%; margin-top: 16px; margin-bottom: 8px" type="dashed" icon="plus" @click="addCurveSite">新增记录</a-button>-->
      </a-card>
      <a-form v-bind="dataForm">
        <a-card v-bind="baseFormCard" title="配置信息">
          <template slot="extra" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
            <a href="#" @click="formulaCalc">公式计算</a>
            <a-divider type="vertical" />
            <a href="#" @click="curvePreviewModal.show">曲线预览</a>
          </template>

          <div class="content-row">
            <a-row v-bind="rowProps">
              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="配置日期" style="margin: 0">
                  <a-date-picker
                    v-decorator="decorators.makeDate"
                    :showTime="true"
                    :disabledDate="moreCurrentDate"
                    format="YYYY-MM-DD HH:mm:ss"
                    :allowClear="false"
                    @change="setValidTime"
                  ></a-date-picker>
                </a-form-item>
              </a-col>

              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="设备" style="margin: 0">
                  <a-select v-decorator="decorators.devId" placeholder="请选择设备" mode="multiple" @change="handleDevChange">
                    <a-select-option v-for="v in devList" :key="v.id.toString()" :value="v.id.toString()"
                      >{{ v.fixedAssetsId }} {{ v.materialName }} {{ v.speType }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </div>

          <div class="content-row">
            <span class="content-row-title ant-form-item-required">标准使用液：</span>
            <template v-if="dataForm.data.solutionId">
              <span style="margin-right: 10px"
                ><a-tag color="pink" style="line-height: 32px; height: 32px; border-style: unset">{{ dataForm.data.solutionName }}</a-tag></span
              >
              <span style="margin-right: 10px" v-if="dataForm.data.solutionVolume">可用量： {{ dataForm.data.solutionVolume }} mL</span>
              <span style="margin-right: 10px" v-if="dataForm.data.solutionVolume"
                >使用量： <a-input-number v-model="dataForm.data.solutionUseVolume" :min="0" style="width: 70px"></a-input-number> mL</span
              >
            </template>
            <span v-if="isEmptySolution" class="empty-data">暂无可用的标准使用液，请先配置</span>
            <a @click="solutionSelectModal.show(typeRecord.solutionTypeId)">请选择标准使用液</a>
            <div v-if="isLessThanSolutionVolume" class="empty-data" style="margin-top: 5px">标准使用液可用量少于使用量，请重新选择标准使用液或配置更多标准使用液</div>
          </div>
          <div class="content-row" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
            <span class="content-row-title ant-form-item-required" style="width: 10%">参比溶液：</span>
            <template v-if="dataForm.data.solventName">
              <span style="margin-right: 10px"
                ><a-tag color="pink" style="line-height: 32px; height: 32px; border-style: unset">{{ dataForm.data.solventName }}</a-tag></span
              >
              <span style="margin-right: 10px" v-if="dataForm.data.solventVolume">可用量： {{ dataForm.data.solventVolume }} {{ dataForm.data.solventUnit }}</span>
              <span style="margin-right: 10px" v-if="dataForm.data.solventVolume"
                >使用量： <a-input-number v-model="dataForm.data.solventUseVolume" :min="0" style="width: 70px"></a-input-number>{{ dataForm.data.solventUnit }}</span
              >
            </template>
            <span v-if="isEmptyReagent" class="empty-data">暂无可用的参比溶液，请先配置</span>
            <a @click="checkReagentSelectModal.show(typeRecord.referenceSolution, 'solvent')">请选择参比溶液</a>
          </div>

          <div class="content-row" v-if="typeRecord.configMode === checkStandardCurveModeEnum.fileMade">
            <a-row v-bind="rowProps">
              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" style="margin: 0">
                  <span slot="label" class="ant-form-item-required">附件上传</span>
                  <a-upload
                    accept="image/*,.pdf,.doc,.docx,.xlsx"
                    :action="uploadUrl"
                    :fileList="fileList"
                    :headers="tokenHeader"
                    class="upload-list-inline"
                    :remove="handleRemove"
                    :multiple="true"
                    @change="handleChange"
                  >
                    <a-button v-if="fileList.length < 1"> <a-icon type="upload" /> 点击上传 </a-button>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>
          </div>

          <div class="content-row" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
            <template v-if="typeRecord.siteModelId === 9">
              <div v-for="(item, index) in curveCalculateResultList" :key="index">
                <div style="font-size: 15px; margin-bottom: 3px">
                  <span style="color: #1890ff">{{ item.testItem }}</span> 的公式计算结果
                </div>
                <a-row v-bind="rowProps">
                  <a-col v-bind="colProps1">
                    <a-form-item class="curveCompositeItemSite" v-bind="formItemLayout1" label="截距(a)" style="margin: 0">
                      <a-input-number v-model="item.intercept" style="width: 160px" disabled />
                    </a-form-item>
                  </a-col>

                  <a-col v-bind="colProps1">
                    <a-form-item class="curveCompositeItemSite" v-bind="formItemLayout1" label="斜率(b)" style="margin: 0">
                      <a-input-number v-model="item.slope" style="width: 160px" disabled />
                    </a-form-item>
                  </a-col>
                  <a-col v-bind="colProps1">
                    <a-form-item class="curveCompositeItemSite" v-bind="formItemLayout1" label="相关系数(r)" style="margin: 0"
                      ><!--R²-->
                      <a-input-number v-model="item.correlationCoefficient" style="width: 160px" @change="(value) => onCorrelChangeComposite(value, item)" disabled />
                      <span style="margin-left: 5px; color: #a8a8a8">≥{{ typeRecord.minCorrel }}</span>
                      <span style="color: #06cb06" v-if="item.correlStatus === 'satisfy'"><a-icon type="check-circle" style="margin: 0 5px" />满足要求</span>
                      <span style="color: red" v-if="item.correlStatus === 'unsatisfy'"><a-icon type="exclamation-circle" style="margin: 0 5px" />不满足要求</span>
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </template>

            <template v-else>
              <a-row v-bind="rowProps">
                <a-col v-bind="colProps1">
                  <a-form-item v-bind="formItemLayout1" label="截距(a)" style="margin: 0">
                    <a-input-number v-decorator="decorators.intercept" style="width: 160px" disabled />
                  </a-form-item>
                </a-col>

                <a-col v-bind="colProps1">
                  <a-form-item v-bind="formItemLayout1" label="斜率(b)" style="margin: 0">
                    <a-input-number v-decorator="decorators.slope" style="width: 160px" disabled />
                  </a-form-item>
                </a-col>
                <a-col v-bind="colProps1">
                  <a-form-item v-bind="formItemLayout1" label="相关系数(r)" style="margin: 0"
                    ><!--R²-->
                    <a-input-number v-decorator="decorators.correlationCoefficient" style="width: 160px" @change="onCorrelChange" disabled />
                    <span style="margin-left: 5px; color: #a8a8a8">≥{{ typeRecord.minCorrel }}</span>
                    <span style="color: #06cb06" v-if="correlStatus === 'satisfy'"><a-icon type="check-circle" style="margin: 0 5px" />满足要求</span>
                    <span style="color: red" v-if="correlStatus === 'unsatisfy'"><a-icon type="exclamation-circle" style="margin: 0 5px" />不满足要求</span>
                  </a-form-item>
                </a-col>
              </a-row>
            </template>
          </div>
        </a-card>

        <a-card v-bind="baseFormCard" title="单点校准">
          <template slot="extra" v-if="notVerifyData">
            <span style="color: red">单点校准数据不全，请先在曲线类型中添加保存</span>
          </template>
          <div class="content-row">
            <a-row v-bind="rowProps">
              <a-col v-bind="colProps1">
                <a-form-item v-bind="formItemLayout1" label="允许范围" style="margin: 0">
                  <!--                  <a-input v-decorator="decorators.verifyAllowRange" style="width: 160px;" />-->
                  <a-input-number
                    disabled
                    v-model="dataForm.data.verifyAllowMinRange"
                    :formatter="(value) => `≥${value}%`"
                    :parser="(value) => value.replace('%', '').replace('≥', '')"
                  />
                  -
                  <a-input-number
                    disabled
                    v-model="dataForm.data.verifyAllowMaxRange"
                    :formatter="(value) => `≤${value}%`"
                    :parser="(value) => value.replace('%', '').replace('≤', '')"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="16">
                <a-form-item label="有效时长" v-bind="formItemLayout1" style="margin: 0">
                  <a-input-number disabled v-decorator="decorators.verifyValidDate" :min="0" :step="1" style="width: 80px; margin-right: 10px"></a-input-number>
                  <a-radio-group disabled v-model="dataForm.data.verifyValidDateUnit">
                    <a-radio v-for="(value, key) of reagentCateValidDateUnitMap" :key="key" :value="key">{{ value }}</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </a-form>
    </a-spin>

    <!-- 参比溶液选择-->
    <a-modal v-bind="checkReagentSelectModal" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
      <check-reagent-list v-bind="checkReagentSelectModal.data" v-on="checkReagentSelectModal.tableAction"></check-reagent-list>
      <template #footer>
        <a-button @click="checkReagentSelectModal.action.cancel">取消</a-button>
        <a-button type="primary" @click="checkReagentSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <!-- 标准使用液液选择-->
    <a-modal v-bind="solutionSelectModal">
      <standard-solution-list v-bind="solutionSelectModal.data" v-on="solutionSelectModal.tableAction"></standard-solution-list>
      <template #footer>
        <a-button @click="solutionSelectModal.action.cancel">取消</a-button>
        <a-button type="primary" @click="solutionSelectModal.action.ok">确定</a-button>
      </template>
    </a-modal>

    <a-modal v-bind="curvePreviewModal" v-if="typeRecord.configMode === checkStandardCurveModeEnum.manMade">
      <composite-curve-preview v-if="curvePreviewModal.isComposite" v-bind="curvePreviewModal.compositeData"></composite-curve-preview>
      <curve-preview v-else v-bind="curvePreviewModal.data"></curve-preview>
      <template #footer>
        <a-button @click="curvePreviewModal.cancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import miniForm from '@/pages/common/mixins/form'
import { checkStandardCurveConfigApi, checkStandardCurveTypeApi } from '@/api/checkStandardCurve/checkStandardCurveApi'
import { sysCommonApi } from '@/api/common'
import { resDevApi } from '@/api/quote'
import { actionColumns, baseCurveDrawColumns, curveDrawModels, remarkCurveDrawColumns } from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable'
import CheckReagentList from '@/pages/cloud-quote/check-reagent/SelectList'
import { checkReagentSelectColumn } from '@/pages/cloud-quote/check-reagent/modules/table/columns'
import Moment from 'moment'
import StandardSolutionList from '@/pages/cloud-quote/check-standard-curve-type/modules/table/StandardSolutionList'
import { checkStandardSolutionCheckStatusEnum } from '@/api/checkStandardSolution/checkStandardSolutionConstant'
import CurvePreview from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable/CurvePreview'
import CompositeCurvePreview from '@/pages/cloud-quote/check-standard-curve-type/modules/curveDrawTable/CompositeCurvePreview'
import { checkStandardCurveModeEnum, checkStandardCurveModeOptions } from '@/api/checkStandardCurve/checkStandardCurveConstant'
import { HotColumn, HotTable } from '@handsontable/vue'
import { registerAllModules } from 'handsontable/registry'
import { reagentCateValidDateUnitMap } from '@/api/checkReagent/checkReagentConstant'
import 'handsontable/dist/handsontable.full.css'
import { autoConfigureApi, sysReviewConfigUserControllerApi } from '@/api/autoConfigure/autoConfigureApi'
import { checkStandardSolutionApi } from '@/api/checkStandardSolution/checkStandardSolutionApi'
import { checkReagentApi } from '@/api/checkReagent/checkReagentApi'

registerAllModules()

export default {
  name: 'MakeForm',
  mixins: [miniForm],
  components: { StandardSolutionList, CheckReagentList, CurvePreview, CompositeCurvePreview, HotTable, HotColumn },
  props: {
    editType: {
      type: String,
      required: false,
      default: 'add'
    },

    id: {
      type: [String, Number, Object],
      required: false,
      default: null
    },

    typeRecord: {
      type: Object,
      required: false,
      default: null
    },

    curveRecord: {
      type: Object,
      required: false,
      default: null
    }

    // columns: {
    //   type: Array,
    //   required: false,
    //   default: () => (defaultCurveDrawColumns)
    // }
  },
  data() {
    return {
      checkStandardSolutionCheckStatusEnum,
      baseCurveDrawColumns,
      curveDrawModels,
      remarkCurveDrawColumns,
      actionColumns,
      checkStandardCurveModeEnum,
      checkStandardCurveModeOptions,
      reagentCateValidDateUnitMap,
      formItemLayout1: {
        labelCol: {
          xxl: { span: 8 },
          xl: { span: 8 },
          lg: { span: 8 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        },
        wrapperCol: {
          xxl: { span: 16 },
          xl: { span: 16 },
          lg: { span: 16 },
          md: { span: 24 },
          sm: { span: 24 },
          xs: { span: 24 }
        }
      },
      colProps1: {
        offset: 0,
        pull: 0,
        push: 0,
        span: 8,
        style: { textAlign: 'left' }
      },
      dataForm: {
        data: {
          id: null,
          curveTypeId: this.typeRecord.id,
          verifyAllowMinRange: this.typeRecord?.verifyAllowMinRange,
          verifyAllowMaxRange: this.typeRecord?.verifyAllowMaxRange,
          verifyValidDateUnit: this.typeRecord?.verifyValidDateUnit
        }
      },
      decorators: {
        intercept: ['intercept', { rules: [{ required: true, message: '请输入a的值！' }] }],
        slope: ['slope', { rules: [{ required: true, message: '请输入b的值！' }] }],
        correlationCoefficient: ['correlationCoefficient', { rules: [{ required: true, message: '请输入R²的值！' }] }],
        makeDate: ['makeDate', { rules: [{ required: true, message: '请选择配置时间！' }] }],
        devId: ['devId', { rules: [{ required: true, message: '请选择设备！' }] }],
        // solutionId: ['solutionId', { rules: [{ required: true, message: '请选择标液！' }] }]
        verifyAllowRange: ['verifyAllowRange', { initialValue: '≤10%', rules: [{ required: true, message: '请输入校准允许范围！' }] }],
        verifyValidDate: ['verifyValidDate', { initialValue: this.typeRecord?.verifyValidDate, rules: [{ required: true, message: '请输入有效期！' }] }]
      },

      devList: [], // 根据设备类型查询的设备列表
      devMap: {},
      selectedDevList: [], // 选中的设备列表
      confirmLoading: false,

      curveDrawTable: {
        rowKey: (item) => `${item.drawIndex}`,
        bordered: true,
        size: 'small',
        dataSource: [],
        // columns: this.columns,
        columns: [],
        pagination: false
      },
      checkReagentSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        destroyOnClose: true,
        title: '请选择使用的参比溶液',
        bodyStyle: { background: '#F0F2F5' },
        selectType: 'solution',

        show: (materialId, selectType) => {
          this.checkReagentSelectModal.selectType = selectType
          this.checkReagentSelectModal.data.cateId = materialId
          this.checkReagentSelectModal.data.validCheckTime = this.timeFormat(this.dataForm.form.getFieldValue('makeDate'))
          this.checkReagentSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.checkReagentSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.checkReagentSelectModal.data.ref].getSelectData()
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            // if (this.checkReagentSelectModal.selectType === 'solution') {
            //   this.dataForm.data.solutionList.splice(0, this.dataForm.data.solutionList.length, ...selectData.map(item => Object.assign({ useVolume: 0 }, item)))
            // } else {
            //   this.dataForm.data.solventList.splice(0, this.dataForm.data.solventList.length, ...selectData.map(item => Object.assign({ useVolume: 0 }, item)))
            // }
            this.isEmptyReagent = false
            this.dataForm.data.solventId = selectData[0].id
            this.dataForm.data.solventName = selectData[0].name
            this.dataForm.data.solventUnit = selectData[0].unit
            this.dataForm.data.solventVolume = selectData[0].volume
            this.dataForm.data.solventUseVolume =
              this.typeRecord && (this.typeRecord.constantVolume || this.typeRecord.constantVolume === 0) ? this.typeRecord.constantVolume : null
            this.checkReagentSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'checkReagentSelectTable',
          cateId: null,
          selectable: true,
          pagination: true,
          validCheckTime: null,
          columns: checkReagentSelectColumn
        },

        tableAction: {}
      },
      solutionSelectModal: {
        visible: false,
        width: '80%',
        maskClosable: false,
        closable: false,
        destroyOnClose: true,
        title: '请选择使用的标准使用液',
        bodyStyle: { background: '#F0F2F5' },

        show: (solutionTypeId) => {
          this.solutionSelectModal.data.solutionTypeId = parseInt(solutionTypeId)
          this.solutionSelectModal.data.validTime = this.timeFormat(this.dataForm.form.getFieldValue('makeDate'))
          this.solutionSelectModal.data.makeDate = this.timeFormat(this.dataForm.form.getFieldValue('makeDate'))
          this.solutionSelectModal.visible = true
        },

        action: {
          cancel: () => {
            this.solutionSelectModal.visible = false
          },

          ok: () => {
            const selectData = this.$refs[this.solutionSelectModal.data.ref].getSelectData()
            console.log('选中的数据', selectData)
            if (!selectData || !selectData.length) {
              this.$message.error('您还未选择！')
              return false
            }
            this.isEmptySolution = false
            this.dataForm.data.solutionId = selectData[0].id
            this.dataForm.data.solutionName = selectData[0].solutionNo + ' ' + selectData[0].name
            this.dataForm.data.solutionVolume = selectData[0].volume
            const useData = this.curveDrawTable.dataSource
            let useVolume = 0.0
            useData.forEach((item) => {
              useVolume += parseFloat(item.standardLiquid)
            })
            this.dataForm.data.solutionUseVolume = useVolume
            if (useVolume > selectData[0].volume) {
              this.isLessThanSolutionVolume = true
            }
            this.solutionSelectModal.action.cancel()
          }
        },

        data: {
          ref: 'solutionSelectTable',
          solutionTypeId: null,
          checkStatus: checkStandardSolutionCheckStatusEnum.pass,
          validTime: null,
          makeDate: null,
          selectType: 'radio'
        },

        tableAction: {}
      },
      curvePreviewModal: {
        visible: false,
        width: 960,
        height: 600,
        maskClosable: false,
        closable: false,
        destroyOnClose: true,
        title: '曲线预览',

        show: async () => {
          const data = this.curveDrawTable.dataSource
          if (this.typeRecord.siteModelId === 9) {
            this.curvePreviewModal.isComposite = true
            const columns = this.curveDrawTable.columns

            this.curveCalculateResultList.forEach((it) => {
              it.calcData = []
              it.curveSiteData = []
              it.axisData = {}
              let concentrationColumn = columns.find((item) => item.dataIndex === 'purity')
              if (concentrationColumn) {
                it.axisData.concentration = concentrationColumn.title
              }
              let resultColumn = null
              switch (it.testItem) {
                case '苯':
                  data.forEach((item) => {
                    it.calcData.push({ concentration: item.purity, result: item.benArea })
                  })
                  resultColumn = columns.find((item) => item.dataIndex === 'benArea')
                  if (resultColumn) {
                    it.axisData.result = resultColumn.title
                  }
                  break
                case '甲苯':
                  data.forEach((item) => {
                    it.calcData.push({ concentration: item.purity, result: item.jiabenArea })
                  })
                  resultColumn = columns.find((item) => item.dataIndex === 'jiabenArea')
                  if (resultColumn) {
                    it.axisData.result = resultColumn.title
                  }
                  break
                case '乙苯':
                  data.forEach((item) => {
                    it.calcData.push({ concentration: item.purity, result: item.yibenArea })
                  })
                  resultColumn = columns.find((item) => item.dataIndex === 'yibenArea')
                  if (resultColumn) {
                    it.axisData.result = resultColumn.title
                  }
                  break
                case '对二甲苯':
                  data.forEach((item) => {
                    it.calcData.push({ concentration: item.purity, result: item.duierjiabenArea })
                  })
                  resultColumn = columns.find((item) => item.dataIndex === 'duierjiabenArea')
                  if (resultColumn) {
                    it.axisData.result = resultColumn.title
                  }
                  break
                case '间二甲苯':
                  data.forEach((item) => {
                    it.calcData.push({ concentration: item.purity, result: item.jianerjiabenArea })
                  })
                  resultColumn = columns.find((item) => item.dataIndex === 'jianerjiabenArea')
                  if (resultColumn) {
                    it.axisData.result = resultColumn.title
                  }
                  break
                case '邻二甲苯':
                  data.forEach((item) => {
                    it.calcData.push({ concentration: item.purity, result: item.linerjiabenArea })
                  })
                  resultColumn = columns.find((item) => item.dataIndex === 'linerjiabenArea')
                  if (resultColumn) {
                    it.axisData.result = resultColumn.title
                  }
                  break
                case '异丙苯':
                  data.forEach((item) => {
                    it.calcData.push({ concentration: item.purity, result: item.yibingbenArea })
                  })
                  resultColumn = columns.find((item) => item.dataIndex === 'yibingbenArea')
                  if (resultColumn) {
                    it.axisData.result = resultColumn.title
                  }
                  break
                case '苯乙烯':
                  data.forEach((item) => {
                    it.calcData.push({ concentration: item.purity, result: item.benyixiArea })
                  })
                  resultColumn = columns.find((item) => item.dataIndex === 'benyixiArea')
                  if (resultColumn) {
                    it.axisData.result = resultColumn.title
                  }
                  break
              }
            })

            try {
              for (let i = 0; i < this.curveCalculateResultList.length; i++) {
                let it = this.curveCalculateResultList[i]

                it.calcData.forEach((item) => {
                  // if (item.concentration === '' || item.result === '') {
                  //   this.$message.warn('请先完善曲线点位信息')
                  //   throw new Error('breakForEach')
                  // }
                  const a = {}
                  for (const i in item) {
                    // if (values.includes(i)) {
                    if (isNaN(parseFloat(item[i]))) {
                      this.$message.warn('请先完善曲线点位信息')
                      throw new Error('breakForEach')
                    } else {
                      a[i] = parseFloat(item[i])
                    }
                    // }
                  }
                  it.curveSiteData.push(a)
                })
                if (!it.equationData || Object.keys(it.equationData).length === 0) {
                  await this.autoFormulaCalcComposite()
                }
              }
              this.curvePreviewModal.compositeData.list = this.curveCalculateResultList

              this.curvePreviewModal.visible = true
            } catch (e) {
              if (e.message !== 'breakForEach') throw e
            }
          } else {
            this.curvePreviewModal.isComposite = false

            const values = ['concentration', 'result']
            const curveSiteData = []

            // 模板2
            const spColumns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('F含量') >= 0)
            // 模板3
            const siteModel3Columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('测定浓度') >= 0 && item.dataIndex.indexOf('result') >= 0)
            // 模板4或8
            const siteModel4h8Columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('测定浓度') >= 0 && item.dataIndex.indexOf('purity') >= 0)
            const calcData = []
            if (spColumns.length > 0) {
              data.forEach((item) => {
                const concentration = item.absorbanceValue ? Math.round(Math.log10(item.absorbanceValue) * 1000) / 1000 : ''
                calcData.push({ concentration, result: item.result })
              })
            } else if (siteModel3Columns.length > 0) {
              // 模板3公式计算
              data.forEach((item) => {
                calcData.push({ concentration: item.result, result: item.absorbanceValue })
              })
            } else if (siteModel4h8Columns.length > 0) {
              // 模板4或8公式计算
              data.forEach((item) => {
                calcData.push({ concentration: item.purity, result: item.result })
              })
            } else {
              data.forEach((item) => {
                calcData.push({ concentration: item.concentration, result: item.result })
              })
            }

            try {
              calcData.forEach((item) => {
                // if (item.concentration === '' || item.result === '') {
                //   this.$message.warn('请先完善曲线点位信息')
                //   throw new Error('breakForEach')
                // }
                const a = {}
                for (const i in item) {
                  // if (values.includes(i)) {
                  if (isNaN(parseFloat(item[i]))) {
                    this.$message.warn('请先完善曲线点位信息')
                    throw new Error('breakForEach')
                  } else {
                    a[i] = parseFloat(item[i])
                  }
                  // }
                }
                curveSiteData.push(a)
              })
              const columns = this.curveDrawTable.columns
              const axisData = {}
              columns.forEach((item) => {
                if (values.includes(item.dataIndex)) axisData[item.dataIndex] = item.title
              })
              if (spColumns.length > 0) {
                // 模板2
                axisData.concentration = spColumns[0].title
              } else if (siteModel3Columns.length > 0) {
                // 模板3
                axisData.concentration = siteModel3Columns[0].title
                let resultColumn = columns.find((item) => item.dataIndex === 'absorbanceValue')
                if (resultColumn) {
                  axisData.result = resultColumn.title
                }
              } else if (siteModel4h8Columns.length > 0) {
                // 模板4或8
                axisData.concentration = siteModel4h8Columns[0].title
              }
              this.curvePreviewModal.data.curveSiteData = curveSiteData
              this.curvePreviewModal.data.axisData = axisData
              if (this.curvePreviewModal.data.equationData === null) {
                this.autoFormulaCalc()
              }
              this.curvePreviewModal.visible = true
            } catch (e) {
              if (e.message !== 'breakForEach') throw e
            }
          }
        },

        cancel: () => {
          this.curvePreviewModal.visible = false
        },

        data: {
          ref: 'curvePreview',
          curveSiteData: null,
          axisData: null,
          equationData: null
        },
        // 复合点位曲线数据
        compositeData: {
          ref: 'compositeCurvePreview',
          list: []
        },
        isComposite: false // 是否复合
      },
      correlStatus: '',
      tokenHeader: { 'Access-Token': this.$store.getters.token },
      uploadUrl: sysCommonApi.uploadFile,
      viewUrl: sysCommonApi.view,
      fileList: [],
      fileAppendix: [],
      // hotData: [
      //   { drawIndex: 1, standardLiquid: 0.1, concentration: 0.2 },
      //   { drawIndex: 2, standardLiquid: '0', concentration: '0' }
      // ],
      hotSetting: {
        height: 'auto',
        rowHeights: 40,
        manualRowResize: true,
        stretchH: 'all',
        className: 'htMiddle htCenter',
        currentHeaderClassName: 'ht__highlight',
        activeHeaderClassName: 'ht__active_highlight',
        licenseKey: 'non-commercial-and-evaluation',
        contextMenu: {
          // 自定义右键菜单，可汉化，默认布尔值
          items: {
            row_above: {
              name: '上方插入一行'
            },
            hsep1: '---------', // 提供分隔线
            row_below: {
              name: '下方插入一行'
            },
            hsep2: '---------', // 提供分隔线
            remove_row: {
              name: '删除行'
            }
          }
        },
        afterInit: () => {
          console.log('init')
        },
        afterChange: (changes) => {
          const self = this
          if (changes !== null) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
              if (self.typeRecord.siteModelId === 1 || self.typeRecord.siteModelId === 8) {
                if (prop === 'absorbanceValue') {
                  const d = self.curveDrawTable.dataSource[row]
                  if (parseFloat(newValue).toString() !== 'NaN' && parseFloat(d.absorbanceValue2).toString() !== 'NaN') {
                    // *100000为了避免计算误差，小数点后位数三位以上加减法计算结果精度存在误差（如计算0.105 - 0.005的结果是0.9999999999999999）
                    // 小数点后位数三位为139的数乘于100000有精度误差，改为1000
                    let result = ((parseFloat(newValue) * 1000 - parseFloat(d.absorbanceValue2) * 1000) / 1000).toString()
                    if (newValue.indexOf('.') >= 0) {
                      const vLength = newValue.split('.')[1].length
                      const rLength = result.indexOf('.') >= 0 ? result.split('.')[1].length : 0
                      for (let i = 0; i < vLength - rLength; i++) {
                        if (rLength === 0 && i === 0) {
                          result += '.0'
                        } else {
                          result += '0'
                        }
                      }
                    }
                    d.result = result
                    self.curveDrawTable.dataSource[row] = d
                  }
                } else if (prop === 'absorbanceValue2') {
                  const d = self.curveDrawTable.dataSource[row]
                  if (parseFloat(newValue).toString() !== 'NaN' && parseFloat(d.absorbanceValue).toString() !== 'NaN') {
                    let result = ((parseFloat(d.absorbanceValue) * 1000 - parseFloat(newValue) * 1000) / 1000).toString()
                    if (d.absorbanceValue.indexOf('.') >= 0) {
                      const vLength = d.absorbanceValue.split('.')[1].length
                      const rLength = result.indexOf('.') >= 0 ? result.split('.')[1].length : 0
                      for (let i = 0; i < vLength - rLength; i++) {
                        if (rLength === 0 && i === 0) {
                          result += '.0'
                        } else {
                          result += '0'
                        }
                      }
                    }
                    d.result = result
                    self.curveDrawTable.dataSource[row] = d
                  }
                }
              }

              if (['concentration', 'result'].includes(prop)) {
                self.autoFormulaCalc()
              }
              if (self.typeRecord.siteModelId === 3 && prop === 'absorbanceValue') {
                self.autoFormulaCalc()
              }
              if (
                self.typeRecord.siteModelId === 9 &&
                ['benArea', 'jiabenArea', 'yibenArea', 'duierjiabenArea', 'jianerjiabenArea', 'linerjiabenArea', 'yibingbenArea', 'benyixiArea'].includes(prop)
              ) {
                self.autoFormulaCalcComposite(prop)
              }
            })
            self.$refs.hotTableComponent.hotInstance.loadData(self.curveDrawTable.dataSource)
          }
        }
      },

      // 新增的曲线Id
      newId: null,
      // 自动曲线复审核审核人员、复核人员
      assignUser: {},
      // 判断曲线分类中是否有单点校准数据
      notVerifyData: false,
      isEmptySolution: false,
      isLessThanSolutionVolume: false,
      isEmptyReagent: false,
      isLessThanReagentVolume: false,
      makeDate: null,
      curveCalculateResultList: []
    }
  },
  created() {
    let makeDate = Moment(new Date(), 'YYYY-MM-DD HH:mm:ss')
    this.makeDate = makeDate
    if (this.curveRecord !== null && Object.keys(this.curveRecord).length > 0) {
      const { intercept, slope, correlationCoefficient, devId, solutionId, solutionName, solventId, solventName, siteModelId, curveCalculateResultList } = this.curveRecord
      // , verifyAllowMinRange, verifyAllowMaxRange, verifyValidDate, verifyValidDateUnit
      makeDate = Moment(this.curveRecord.makeDate == null ? new Date() : this.curveRecord.makeDate, 'YYYY-MM-DD HH:mm:ss')
      this.dataForm.form.$nextTick(() => {
        this.dataForm.form.setFieldsValue({
          intercept,
          slope,
          correlationCoefficient,
          makeDate,
          verifyValidDate: this.typeRecord?.verifyValidDate,
          devId: devId ? devId.split(',') : []
        })
        this.onCorrelChange(correlationCoefficient)
      })

      // 判断当已有曲线数据是模板9时，是复合因子曲线
      if (siteModelId === 9) {
        this.curveCalculateResultList = curveCalculateResultList || []
        this.curveCalculateResultList.forEach((it) => {
          switch (it.testItem) {
            case '苯':
              it.dataIndex = 'benArea'
              break
            case '甲苯':
              it.dataIndex = 'jiabenArea'
              break
            case '乙苯':
              it.dataIndex = 'yibenArea'
              break
            case '对二甲苯':
              it.dataIndex = 'duierjiabenArea'
              break
            case '间二甲苯':
              it.dataIndex = 'jianerjiabenArea'
              break
            case '邻二甲苯':
              it.dataIndex = 'linerjiabenArea'
              break
            case '异丙苯':
              it.dataIndex = 'yibingbenArea'
              break
            case '苯乙烯':
              it.dataIndex = 'benyixiArea'
              break
          }
          this.onCorrelChangeComposite(it.correlationCoefficient, it)
        })
      }

      this.dataForm.data = {
        id: this.curveRecord.id,
        curveTypeId: this.typeRecord.id,
        solventId,
        solventName,
        solutionId,
        solutionName,
        verifyAllowMinRange: this.typeRecord?.verifyAllowMinRange,
        verifyAllowMaxRange: this.typeRecord?.verifyAllowMaxRange,
        verifyValidDateUnit: this.typeRecord?.verifyValidDateUnit
      }
    } else {
      this.dataForm.form.$nextTick(() => {
        this.dataForm.form.setFieldsValue({ makeDate })
      })
    }
    this.setValidTime(makeDate)

    if (this.typeRecord !== null) {
      if (!this.typeRecord.verifyAllowMinRange || !this.typeRecord.verifyAllowMaxRange || !this.typeRecord.verifyValidDate || !this.typeRecord.verifyValidDateUnit) {
        this.notVerifyData = true
      }
      this.getDevList(this.typeRecord.devTypeId)

      if (!this.typeRecord.configMode) this.typeRecord.configMode = checkStandardCurveModeEnum.manMade

      if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
        // this.getSolutionList(this.typeRecord.solutionTypeId)
        this.initSiteUnit(this.typeRecord.siteModelId !== null ? this.typeRecord.siteModelId : 1)
        if (this.editType === 'add') {
          this.getCurveTypeSite(this.typeRecord.id)
        } else {
          this.getCurveSite(this.curveRecord.id)
        }
      }

      // 判断当曲线类型数据是模板9，并且曲线公式计算结果为空时，初始化
      if (this.typeRecord.siteModelId === 9 && this.curveCalculateResultList.length === 0) {
        this.curveCalculateResultList = [
          { testItem: '苯', dataIndex: 'benArea' },
          { testItem: '甲苯', dataIndex: 'jiabenArea' },
          { testItem: '乙苯', dataIndex: 'yibenArea' },
          { testItem: '对二甲苯', dataIndex: 'duierjiabenArea' },
          { testItem: '间二甲苯', dataIndex: 'jianerjiabenArea' },
          { testItem: '邻二甲苯', dataIndex: 'linerjiabenArea' },
          { testItem: '异丙苯', dataIndex: 'yibingbenArea' },
          { testItem: '苯乙烯', dataIndex: 'benyixiArea' }
        ]
      }

      if (this.editType === 'edit' && this.typeRecord.configMode === checkStandardCurveModeEnum.fileMade) {
        this.fileAppendix = JSON.parse(this.curveRecord.fileAppendix)
        const fileList = []
        this.fileAppendix.forEach((it) => fileList.push({ uid: it.uid, name: it.fileName, status: 'done', url: this.viewUrl + it.fileUrl }))
        this.fileList.splice(0, this.fileList.length, ...fileList)
      }
      // console.log(this.typeRecord)
    }
  },
  mounted() {
    if (!this.curveRecord || Object.keys(this.curveRecord).length === 0) {
      this.defaultSolutionList(this.typeRecord.solutionTypeId)
      this.defaultReagentList(this.typeRecord.referenceSolution)
    }
  },
  methods: {
    // 默认显示的标准使用液
    defaultSolutionList(solutionTypeId) {
      let queryParam = {
        pageNo: 1,
        pageSize: 10000,
        page: true,
        checkStatus: checkStandardSolutionCheckStatusEnum.pass,
        validTime: this.timeFormat(this.makeDate),
        solutionTypeId: parseInt(solutionTypeId),
        compositionName: '',
        makeDateBegin: '',
        makeDateEnd: '',
        queryType: 0
        // page: false
      }
      checkStandardSolutionApi.list(queryParam).then((res) => {
        console.log(res)
        // if (res.code===0) {
        if (res.data && res.data.length > 0) {
          let selectData = res.data
          let solution = selectData.find((item) => this.makeDate && Moment(this.makeDate).isSameOrAfter(item.makeTime) && Moment(this.makeDate).isSameOrBefore(item.validTime))
          if (solution) {
            this.dataForm.data.solutionId = solution.id
            this.dataForm.data.solutionName = solution.solutionNo + ' ' + solution.name
            this.dataForm.data.solutionVolume = solution.volume
            const useData = this.curveDrawTable.dataSource
            let useVolume = 0.0
            useData.forEach((item) => {
              useVolume += parseFloat(item.standardLiquid)
            })
            this.dataForm.data.solutionUseVolume = useVolume
            if (useVolume > solution.volume) {
              this.isLessThanSolutionVolume = true
            }
            this.$forceUpdate()
          } else {
            this.isEmptySolution = true
          }
        } else {
          this.isEmptySolution = true
        }
        // }
      })
    },
    // 默认显示的参比溶液
    defaultReagentList(materialId) {
      let queryParam = {
        cateId: materialId,
        validCheckTime: this.timeFormat(this.makeDate),
        page: false
      }
      checkReagentApi.list(queryParam).then((res) => {
        console.log(res)
        if (res.code === 0) {
          if (res.data.length > 0) {
            let selectData = res.data
            let solvent = selectData.find((item) => this.makeDate && Moment(this.makeDate).isSameOrAfter(item.configTime) && Moment(this.makeDate).isSameOrBefore(item.validTime))
            if (solvent) {
              this.dataForm.data.solventId = solvent.id
              this.dataForm.data.solventName = solvent.name
              this.dataForm.data.solventUnit = solvent.unit
              this.dataForm.data.solventVolume = solvent.volume
              this.dataForm.data.solventUseVolume =
                this.typeRecord && (this.typeRecord.constantVolume || this.typeRecord.constantVolume === 0) ? this.typeRecord.constantVolume : null
            } else {
              this.isEmptyReagent = true
            }
          } else {
            this.isEmptyReagent = true
          }
        }
      })
    },
    initSiteUnit(siteId) {
      const columns = this.curveDrawModels.filter((item) => item.id === siteId)[0].columns
      // const allColumns = JSON.parse(JSON.stringify([...baseCurveDrawColumns, ...columns, ...remarkCurveDrawColumns, ...actionColumns]))
      const allColumns = JSON.parse(JSON.stringify([...baseCurveDrawColumns, ...columns, ...remarkCurveDrawColumns])) // 使用hotTable后不需要添加操作列，右键菜单添加删除列
      allColumns.forEach((item) => {
        if (
          this.typeRecord.hasOwnProperty(item.dataIndex) &&
          this.typeRecord[item.dataIndex] !== null &&
          this.typeRecord[item.dataIndex] !== '' &&
          this.typeRecord.siteModelId !== 9
        ) {
          item.title = item.title + '（' + this.typeRecord[item.dataIndex] + '）'
        }
        if (
          this.typeRecord.siteModelId === 9 &&
          this.typeRecord.benArea &&
          ['benArea', 'jiabenArea', 'yibenArea', 'duierjiabenArea', 'jianerjiabenArea', 'linerjiabenArea', 'yibingbenArea', 'benyixiArea'].includes(item.dataIndex)
        ) {
          item.title = item.title + '（' + this.typeRecord.benArea + '）'
        }

        if (siteId == 3) {
          if (item.dataIndex === 'standardLiquid' || item.dataIndex === 'concentration' || item.dataIndex === 'result') {
            item.readOnly = true
          }
        }
        if (siteId == 4 || siteId == 8 || siteId == 9) {
          if (item.dataIndex === 'standardLiquid' || item.dataIndex === 'concentration' || item.dataIndex === 'purity') {
            item.readOnly = true
          }
        }
      })
      // console.log(this.typeRecord)
      // console.log(allColumns)
      this.curveDrawTable.columns = allColumns
    },
    getCurveTypeSite(id) {
      const param = { id: id }
      checkStandardCurveTypeApi.curveTypeSite(param).then((res) => {
        if (res.code === 0 && res.data.length > 0) {
          const drawList = []
          res.data.forEach((item) => {
            let obj = {
              drawIndex: item.drawIndex,
              standardLiquid: item.standardLiquid,
              concentration: item.concentration
            }
            if (this.typeRecord.siteModelId == 3) {
              obj.result = item.measureConcentration
            }
            if (
              this.typeRecord.siteModelId == 4 ||
              this.typeRecord.siteModelId == 8 ||
              this.typeRecord.siteModelId == 9 ||
              this.typeRecord.siteModelId == 10 ||
              this.typeRecord.siteModelId == 11
            ) {
              obj.purity = item.measureConcentration
            }
            drawList.push(obj)
          })
          this.curveDrawTable.dataSource = drawList
          this.$refs.hotTableComponent.hotInstance.loadData(drawList)
        }
      })
    },
    getCurveSite(id) {
      const param = { id: id }
      checkStandardCurveConfigApi.curveSite(param).then((res) => {
        if (res.code === 0 && res.data.length > 0) {
          let drawList = res.data.filter((item) => !item.itemId)
          if (drawList.length) {
            this.curveDrawTable.dataSource = drawList
            this.$refs.hotTableComponent.hotInstance.loadData(drawList)
          } else {
            this.getCurveTypeSite(this.typeRecord.id)
          }
        }
      })
    },
    save() {
      const self = this
      if (!self.confirmLoading) {
        if (this.typeRecord.siteModelId === 9) {
          let unsatisfyList = this.curveCalculateResultList.filter((item) => item.correlStatus === 'unsatisfy')
          if (unsatisfyList.length > 0 && this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
            /* self.$confirm({
            // title: '确认撤回吗?',
            title: '',
            content: `相关系数小于设置的最小值，是否继续提交？`,
            onOk () {
              self.saveData()
            },
            onCancel () {
            }
          }) */
            self.$message.error(`相关系数小于设置的最小值，无法提交`)
          } else {
            self.saveData()
          }
        } else {
          if (self.correlStatus === 'unsatisfy' && this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
            /* self.$confirm({
            // title: '确认撤回吗?',
            title: '',
            content: `相关系数小于设置的最小值，是否继续提交？`,
            onOk () {
              self.saveData()
            },
            onCancel () {
            }
          }) */
            self.$message.error(`相关系数小于设置的最小值，无法提交`)
          } else {
            self.saveData()
          }
        }
      }
    },
    saveData() {
      const self = this
      self.dataForm.form.validateFields(async (errors, values) => {
        if (!errors) {
          if (!self.dataForm.data.hasOwnProperty('solutionId') || typeof self.dataForm.data.solutionId === 'undefined' || self.dataForm.data.solutionId === '') {
            this.$message.error(`请选择标液！`)
            return
          }

          let solutions = await this.getSolutionOne(this.dataForm.data.solutionId)
          let correctTime = values.makeDate.isSameOrAfter(solutions[0].makeTime) && values.makeDate.isSameOrBefore(solutions[0].validTime)
          if (correctTime === false) {
            this.$message.error('配置时间不在范围之内，请对照‘标准使用液’的配置时间和有效期时间')
            return
          }

          if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
            let solvent = await this.getSolventOne(this.dataForm.data.solventId)
            let correctSolventTime = values.makeDate.isSameOrAfter(solvent.configTime) && values.makeDate.isSameOrBefore(solvent.validTime)
            if (correctSolventTime === false) {
              this.$message.error('配置时间不在范围之内，请对照‘参比溶液’的配置时间和有效期时间')
              return
            }
          }

          if (self.typeRecord.configMode === checkStandardCurveModeEnum.fileMade && this.fileAppendix.length === 0) {
            this.$message.error(`请上传附件！`)
            return
          }
          let list = []
          if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade && self.curveCalculateResultList && self.curveCalculateResultList.length > 0) {
            for (let i = 0; i < self.curveCalculateResultList.length; i++) {
              let item = self.curveCalculateResultList[i]
              if ((!item.intercept && item.intercept !== 0) || (!item.slope && item.slope !== 0) || (!item.correlationCoefficient && item.correlationCoefficient !== 0)) {
                this.$message.error('请先完善曲线点位信息！')
                return
              } else {
                let obj = {
                  testItem: item.testItem,
                  intercept: item.intercept,
                  slope: item.slope,
                  correlationCoefficient: item.correlationCoefficient
                }
                if (item.id) {
                  obj.id = item.id
                }
                if (item.curveId) {
                  obj.curveId = item.curveId
                }
                list.push(obj)
              }
            }
          }
          let { verifyAllowMinRange, verifyAllowMaxRange, verifyValidDateUnit } = self.dataForm.data
          let verifyValidDate = values.verifyValidDate
          if (!verifyAllowMinRange || !verifyAllowMaxRange || !verifyValidDate || !verifyValidDateUnit) {
            this.$message.error(`单点校准数据不全，请先在曲线类型中添加保存！`)
            return
          }
          this.$emit('updateCurveLoading', true)
          self.confirmLoading = true
          values.devId = values.devId.join(',')
          values.devList = this.selectedDevList
          const data = Object.assign(this.curveRecord, values, self.dataForm.data, { curveCalculateResultList: list, mixSolutionList: [] })
          if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade) {
            data.drawList = self.curveDrawTable.dataSource
          } else if (this.typeRecord.configMode === checkStandardCurveModeEnum.fileMade) {
            data.fileAppendix = JSON.stringify(this.fileAppendix)
          }
          data.makeDate = data.makeDate ? self.timeFormat(data.makeDate) : null
          data.validTime = data.validTime ? self.timeFormat(data.validTime) : null
          // console.log(data)
          checkStandardCurveConfigApi
            .save(data)
            .then((res) => {
              if (res.code === 0) {
                self.$message.success(res.msg)
                // self.$emit('success')
                self.newId = res.data.modelId
                self.getStandardSolutionReviewConfig()
              } else {
                self.$message.error(res.msg)
                self.$emit('error')
                self.$emit('updateCurveLoading', false)
              }
            })
            .catch((err) => {
              self.$emit('updateCurveLoading', false)
            })
            .finally(() => {
              self.confirmLoading = false
            })
        } else {
          this.$emit('updateCurveLoading', false)
        }
      })
    },
    /**
     * 该方法已过时，现点位table不使用
     */
    addCurveSite() {
      const data = this.curveDrawTable.dataSource
      const length = data.length
      data.push({
        drawIndex: length === 0 ? '1' : (length + 1).toString(),
        standardLiquid: '',
        concentration: '',
        absorbanceValue: '',
        absorbanceValue2: '',
        correctAbsorbance: '',
        result: '',
        editable: true
      })
      this.curveDrawTable.dataSource = data
    },
    /**
     * 该方法已过时，现点位table不使用
     */
    deleteCurveSite(index) {
      this.curveDrawTable.dataSource.splice(index, 1)
      this.curveDrawTable.dataSource.forEach((item, i) => {
        item.drawIndex = i + 1
      })
    },
    autoFormulaCalc() {
      const data = this.curveDrawTable.dataSource
      let flag = data.length > 0
      // console.log(this.curveDrawTable.columns)
      // 模板2
      const columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('F含量') >= 0)
      // 模板3
      const siteModel3Columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('测定浓度') >= 0 && item.dataIndex.indexOf('result') >= 0)
      // 模板4或8
      const siteModel4h8Columns = this.curveDrawTable.columns.filter((item) => item.title.indexOf('测定浓度') >= 0 && item.dataIndex.indexOf('purity') >= 0)
      // console.log(columns)
      const calcData = []
      if (columns.length > 0) {
        data.forEach((item) => {
          if (!item.hasOwnProperty('absorbanceValue') || item.absorbanceValue === '' || !item.hasOwnProperty('result') || item.result === '') {
            flag = false
          } else {
            const concentration = Math.round(Math.log10(item.absorbanceValue) * 1000) / 1000
            calcData.push({ concentration, result: item.result })
          }
        })
      } else if (siteModel3Columns.length > 0) {
        // 模板3公式计算
        data.forEach((item) => {
          if (!item.hasOwnProperty('absorbanceValue') || item.absorbanceValue === '' || !item.hasOwnProperty('result') || item.result === '') {
            flag = false
          } else {
            calcData.push({ concentration: item.result, result: item.absorbanceValue })
          }
        })
      } else if (siteModel4h8Columns.length > 0) {
        // 模板4或8公式计算
        data.forEach((item) => {
          if (!item.hasOwnProperty('purity') || item.purity === '' || !item.hasOwnProperty('result') || item.result === '') {
            flag = false
          } else {
            calcData.push({ concentration: item.purity, result: item.result })
          }
        })
      } else {
        data.forEach((item) => {
          if (!item.hasOwnProperty('concentration') || item.concentration === '' || !item.hasOwnProperty('result') || item.result === '') {
            flag = false
          } else {
            calcData.push({ concentration: item.concentration, result: item.result })
          }
        })
      }
      if (flag) {
        checkStandardCurveConfigApi.formulaCalc({ drawList: calcData, minCorrel: this.typeRecord.minCorrel }).then((res) => {
          if (res.code === 0) {
            const { intercept, slope, correlationCoefficient } = res.data
            this.curvePreviewModal.data.equationData = { intercept, slope, correlationCoefficient }
            this.dataForm.form.$nextTick(() => {
              this.dataForm.form.setFieldsValue({ intercept, slope, correlationCoefficient })
              this.onCorrelChange(correlationCoefficient)
            })
          }
        })
      }
      return flag
    },
    // 复合因子的计算
    autoFormulaCalcComposite(dataIndex) {
      const data = this.curveDrawTable.dataSource
      // console.log(this.curveDrawTable.columns)
      this.curveCalculateResultList.forEach((it) => {
        it.calcData = []
        it.flag = true

        switch (it.testItem) {
          case '苯':
            data.forEach((item) => {
              if (!item.hasOwnProperty('purity') || !item.purity || !item.hasOwnProperty('benArea') || !item.benArea) {
                it.flag = false
              } else {
                it.calcData.push({ concentration: item.purity, result: item.benArea })
              }
            })

            break
          case '甲苯':
            data.forEach((item) => {
              if (!item.hasOwnProperty('purity') || !item.purity || !item.hasOwnProperty('jiabenArea') || !item.jiabenArea) {
                it.flag = false
              } else {
                it.calcData.push({ concentration: item.purity, result: item.jiabenArea })
              }
            })
            break
          case '乙苯':
            data.forEach((item) => {
              if (!item.hasOwnProperty('purity') || !item.purity || !item.hasOwnProperty('yibenArea') || !item.yibenArea) {
                it.flag = false
              } else {
                it.calcData.push({ concentration: item.purity, result: item.yibenArea })
              }
            })
            break
          case '对二甲苯':
            data.forEach((item) => {
              if (!item.hasOwnProperty('purity') || !item.purity || !item.hasOwnProperty('duierjiabenArea') || !item.duierjiabenArea) {
                it.flag = false
              } else {
                it.calcData.push({ concentration: item.purity, result: item.duierjiabenArea })
              }
            })
            break
          case '间二甲苯':
            data.forEach((item) => {
              if (!item.hasOwnProperty('purity') || !item.purity || !item.hasOwnProperty('jianerjiabenArea') || !item.jianerjiabenArea) {
                it.flag = false
              } else {
                it.calcData.push({ concentration: item.purity, result: item.jianerjiabenArea })
              }
            })
            break
          case '邻二甲苯':
            data.forEach((item) => {
              if (!item.hasOwnProperty('purity') || !item.purity || !item.hasOwnProperty('linerjiabenArea') || !item.linerjiabenArea) {
                it.flag = false
              } else {
                it.calcData.push({ concentration: item.purity, result: item.linerjiabenArea })
              }
            })
            break
          case '异丙苯':
            data.forEach((item) => {
              if (!item.hasOwnProperty('purity') || !item.purity || !item.hasOwnProperty('yibingbenArea') || !item.yibingbenArea) {
                it.flag = false
              } else {
                it.calcData.push({ concentration: item.purity, result: item.yibingbenArea })
              }
            })

            break
          case '苯乙烯':
            data.forEach((item) => {
              if (!item.hasOwnProperty('purity') || !item.purity || !item.hasOwnProperty('benyixiArea') || !item.benyixiArea) {
                it.flag = false
              } else {
                it.calcData.push({ concentration: item.purity, result: item.benyixiArea })
              }
            })

            break
        }
      })
      this.curveCalculateResultList.forEach((it) => {
        if (!dataIndex || (dataIndex && it.dataIndex == dataIndex)) {
          if (it.flag) {
            checkStandardCurveConfigApi.formulaCalc({ drawList: it.calcData, minCorrel: this.typeRecord.minCorrel }).then((res) => {
              if (res.code === 0) {
                const { intercept, slope, correlationCoefficient } = res.data
                it.equationData = { intercept, slope, correlationCoefficient }

                this.dataForm.form.$nextTick(() => {
                  // it.intercept = intercept
                  // it.slope = slope
                  // it.correlationCoefficient = correlationCoefficient
                  this.$set(it, 'intercept', intercept)
                  this.$set(it, 'slope', slope)
                  this.$set(it, 'correlationCoefficient', correlationCoefficient)
                  this.onCorrelChangeComposite(correlationCoefficient, it)
                })
              }
            })
          } else {
            this.$set(it, 'intercept', undefined)
            this.$set(it, 'slope', undefined)
            this.$set(it, 'correlationCoefficient', undefined)
            this.onCorrelChangeComposite(undefined, it)
          }
        }
      })
    },
    formulaCalc() {
      let flag = false
      if (this.typeRecord.siteModelId === 9) {
        flag = this.autoFormulaCalcComposite()
      } else {
        flag = this.autoFormulaCalc()
      }

      if (!flag) {
        this.$message.warn('请先完善曲线点位信息')
      }
    },
    onCorrelChange(value) {
      if (typeof value === 'undefined') {
        this.correlStatus = ''
      } else if (value < this.typeRecord.minCorrel) {
        this.correlStatus = 'unsatisfy'
      } else {
        this.correlStatus = 'satisfy'
      }
    },
    // 复合因子的判断
    onCorrelChangeComposite(value, it) {
      if (typeof value === 'undefined') {
        // it.correlStatus = ''
        this.$set(it, 'correlStatus', '')
      } else if (value < this.typeRecord.minCorrel) {
        // it.correlStatus = 'unsatisfy'
        this.$set(it, 'correlStatus', 'unsatisfy')
      } else {
        // it.correlStatus = 'satisfy'
        this.$set(it, 'correlStatus', 'satisfy')
      }
    },
    handleRemove(info) {
      this.fileAppendix = this.fileAppendix.filter((file) => file.uid !== info.uid)
      this.fileList = this.fileList.filter((r) => r.uid !== info.uid)
    },
    handleChange(info) {
      this.fileList = info.fileList
      if (info.file.status === 'done') {
        if (info.file.response.code === 0) {
          this.$message.success(`${info.file.name} 文件上传成功`)
          const fileUrl = info.file.response.data.fileUrl
          const fileName = info.file.response.data.fileName
          info.file.thumbUrl = this.viewUrl + fileUrl
          info.file.url = this.viewUrl + fileName
          this.fileAppendix.push({ uid: info.file.uid, fileUrl: fileUrl, fileName: fileName })
        } else {
          this.$message.error(`文件上传失败: ${info.file.response.msg}.`)
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`文件上传失败: ${info.file.msg} `)
      }
    },
    getDevList(typeId) {
      resDevApi.getDevsByTypes({ typeId: typeId }).then((res) => {
        this.devList = res
        this.devMap = this.devList.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
      })
    },
    handleDevChange(value) {
      const data = value.map((it) => this.devMap[it])
      this.selectedDevList.splice(0, this.selectedDevList.length, ...data)
    },
    // getSolutionList (typeId) {
    //   checkStandardCurveConfigApi.solutionList({ solutionTypeId: typeId }).then(res => {
    //     if (res.code === 0) {
    //       this.solutionList = res.data
    //     }
    //   })
    // },
    // 根据试剂类目配置获取有效期止
    getValidTime(current) {
      const { validDateUnit, validDate } = this.typeRecord
      return this.moment(current).add(validDate, validDateUnit)
    },
    // 根据试剂类目配置设置有效期止
    async setValidTime(current) {
      const validTime = this.getValidTime(current)
      // console.log('validTime',Moment(validTime).format(),Moment(current).format())
      this.dataForm.data.validTime = validTime
      if (this.dataForm.data.solutionId) {
        let solutions = await this.getSolutionOne(this.dataForm.data.solutionId)
        console.log('66666666', solutions[0])
        let correctTime = current.isSameOrAfter(solutions[0].makeTime) && current.isSameOrBefore(solutions[0].validTime)
        if (correctTime === false) {
          this.$message.error('配置时间不在范围之内，请对照‘标准使用液’的配置时间和有效期时间')
        }
      }
      if (this.typeRecord.configMode === checkStandardCurveModeEnum.manMade && this.dataForm.data.solventId) {
        let solvent = await this.getSolventOne(this.dataForm.data.solventId)
        console.log('88888888', solvent)
        let correctTime = current.isSameOrAfter(solvent.configTime) && current.isSameOrBefore(solvent.validTime)
        if (correctTime === false) {
          this.$message.error('配置时间不在范围之内，请对照‘参比溶液’的配置时间和有效期时间')
        }
      }
    },
    getSolutionOne(id) {
      return checkStandardSolutionApi.list({ id, page: false }).then((res) => {
        return res.data
      })
    },
    getSolventOne(id) {
      return checkReagentApi.findById(id).then((res) => {
        console.log(res)
        return res.data
      })
    },
    // trim (str) {
    //   return str.replace(/(^\s*)|(\s*$)/g, '')
    // },
    // checkNum (str) {
    //   const reg = /[^(0-9)|(.)]/g
    //   return reg.test(str)
    // }

    // 判断是否为自动曲线复审核
    getStandardSolutionReviewConfig() {
      autoConfigureApi
        .selectConfigByType({ type: 'curve_review_config' })
        .then((res) => {
          // 如果是1，自动曲线复审核
          if (res == 1) {
            this.$message.warning('正在自动复审核，请勿进行其它操作！')
            this.selectUserList()
          } else {
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 获取自动曲线复审核 复核人员
    selectUserList() {
      sysReviewConfigUserControllerApi
        .selectUserList({ type: 'qx', id: this.newId })
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.assignUser = res.data
            this.autoAssignReviewedCheckUser()
          } else {
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 自动指派复核人
    autoAssignReviewedCheckUser() {
      let assignDataArr = [{ id: this.newId, reviewUser: this.assignUser.reviewedCheckUser }]
      checkStandardCurveConfigApi
        .assign(assignDataArr)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('曲线配置自动指派复核人成功')
            this.autoReview()
          } else {
            this.$message.error(res.msg)
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 自动复核
    autoReview() {
      const reviewDataArr = [{ id: this.newId, reviewNote: null, reviewStatus: '1', reviewUser: this.assignUser.reviewedCheckUser }]

      checkStandardCurveConfigApi
        .review({ models: reviewDataArr, checkUser: null, checkPwd: null })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('曲线配置自动复核成功')
            this.selectUserListReviewedUser()
          } else {
            this.$message.error(res.msg)
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 获取自动曲线复审核 审核人员
    selectUserListReviewedUser() {
      sysReviewConfigUserControllerApi
        .selectUserList({ type: 'qx', id: this.newId })
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.assignUser = res.data
            this.autoCheck()
          } else {
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    },
    // 自动审核
    autoCheck() {
      const checkDataArr = [{ id: this.newId, checkNote: null, checkPwd: null, checkStatus: '2', checkUser: this.assignUser.reviewedUser }]

      checkStandardCurveConfigApi
        .autoCheck(checkDataArr)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('曲线配置自动审核成功')
            this.newId = null
            this.assignUser = {}
            this.$emit('success')
          } else {
            this.$message.error(res.msg)
            this.$emit('success')
          }
        })
        .catch((err) => {
          this.$emit('success')
        })
    }
  }
}
</script>

<style scoped>
.descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
  background-color: #fafafa;
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}

.descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  overflow-wrap: break-word;
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}

.descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.descriptions-view {
  border: 1px solid #f0f0f0;
}

.content-row {
  border: 1px dashed #e8e8e8;
  margin-bottom: 10px;
  padding: 10px 20px;
}

.content-row-title {
  /*padding: 10px 10px 10px 20px;*/
  line-height: 32px;
  /*color: #4daaff;*/
  color: rgba(0, 0, 0, 0.85);
}
.empty-data {
  color: red;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1rem;
}
</style>
<style lang="less">
.curveCompositeItemSite .ant-form-item-label:before {
  display: inline-block;
  margin-right: 4px;
  content: '*';
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: #f5222d;
}
</style>